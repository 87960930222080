<template>
    <div>
        <CRow>
            <CCol sm="12" xl="12">
                <CCard>
                    <CCardHeader>
                        <strong>Last Data</strong>
                    </CCardHeader>
                    <CCardBody>
                        <pre style="text-align:left;direction:ltr;">{{last_data}}</pre>
                    </CCardBody>
                    <CCardFooter>
                        <CButton color="dark" @click="go_back">Back</CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
export default {
    data () {
        return {
            last_data: '',
        }
    },
    mounted(){
        this.get();
    },
    methods: {
        get() {
            this.axios.get('arbitrage/data_sources/'
                + '?id=' + this.$route.params.id)
                .then(res => {
                    this.last_data = res.data[0].last_data;
                })
        },
        go_back(){
            this.$router.go(-1);
        }
    },
}
</script>
